import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppState } from '../../core/reducers/index';
import ServicesActions from './store/services.actions';

@Injectable({
  providedIn: 'root',
})
export class ServicesResolver implements Resolve<boolean> {
  constructor(private store: Store<AppState>) {}

  resolve(): Observable<any> {
    return this.store.pipe(
      tap(() => this.store.dispatch(ServicesActions.getConfigs())),
      take(1),
    );
  }
}
