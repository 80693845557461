import { faPlusSquare, faMinusSquare } from '@fortawesome/free-regular-svg-icons'
import { Component, Input } from '@angular/core'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { UtilsService } from '../../../utils/utils.service'

@Component({
  selector: 'fs-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent {
  @Input() label?: string
  @Input() description?: string
  @Input() icon?: string | IconDefinition
  @Input() link?: string | string[]
  @Input() class?: string
  @Input() noActive = false
  @Input() exact = false
  @Input() navClosed?: boolean
  @Input() noRipple = false
  @Input() hasChildren = false
  @Input() noToggle = false

  faAngleLeft = faAngleLeft
  faAngleUp = faAngleUp
  faPlusSquare = faPlusSquare
  faMinusSquare = faMinusSquare

  open: boolean

  constructor(public utilsService: UtilsService) {
    this.open = false
  }

  onClick(event: MouseEvent) {
    if (this.noActive) {
      event.stopPropagation()
      event.preventDefault()
    }
    if (this.hasChildren && !this.noToggle) this.noActive ? this.toggleOpen() : this.setOpen()
  }

  toggleOpen() {
    this.open = !this.open
  }

  setOpen() {
    this.open = true
  }

  setClose() {
    this.open = false
  }
}
