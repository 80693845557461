import { routerReducer, RouterReducerState } from '@ngrx/router-store'
import { ActionReducerMap, MetaReducer } from '@ngrx/store'
import { Product } from '../../models/Product'
import { AuthUser } from '@sauna-net/dto'
import { auth } from './auth.reducer'
import { myharvia } from './myharvia.reducer'
import { services } from '../../app/services/store/services.reducer'
import { ServicesState } from '../../app/services/model/ServicesState'

export interface AppState {
  router: RouterReducerState
  myharvia: { total: number; loading: boolean; items: Product[] }
  auth: IAuthState
  services: ServicesState
}

export interface IAuthState {
  token?: string
  isLoggedIn: boolean
  user?: AuthUser
  baseUrl?: string
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  auth,
  myharvia,
  services,
}

export const metaReducers: MetaReducer<AppState>[] = []
// !environment.production ? [] : [];
