import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatRippleModule } from '@angular/material/core'
import { MatDividerModule } from '@angular/material/divider'
import { MatMenuModule } from '@angular/material/menu'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { BrowserModule, Title } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { LoadingBarModule } from '@ngx-loading-bar/core'
import { LoadingBarRouterModule } from '@ngx-loading-bar/router'
// import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { IconModule } from '@ant-design/icons-angular'
import { app_providers } from '../constants/app_providers'
import { MyHarviaEffects } from '../core/effects/myharvia.effects'
import { metaReducers, reducers } from '../core/reducers'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HeaderComponent } from './components/header/header.component'
import { NavItemComponent } from './components/nav-item/nav-item.component'
import { RolesGuardModule } from './components/roles-guard/roles-guard.module'
import { UserNavBarModule } from './components/user-nav-bar/user-nav-bar.module'

import { APP_INITIALIZER, ErrorHandler } from '@angular/core'
import { Router } from '@angular/router'

import { registerLocaleData } from '@angular/common'
import cs from '@angular/common/locales/cs'

// const socketConfig: SocketIoConfig = { url: '/', options: {} };

registerLocaleData(cs)

@NgModule({
  declarations: [AppComponent, HeaderComponent, NavItemComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    IconModule,
    MatRippleModule,
    MatMenuModule,
    HttpClientModule,
    MatDividerModule,
    MatButtonModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreRouterConnectingModule.forRoot(),
    ...(environment.production ? [] : [StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })]),
    EffectsModule.forRoot([MyHarviaEffects]),
    UserNavBarModule,
    RolesGuardModule,
    MatProgressBarModule,
    LoadingBarRouterModule,
    LoadingBarModule,
  ],
  providers: [
    ...app_providers,
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
// SocketIoModule.forRoot(socketConfig),
