import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AccessGuard } from '../guards/access.guard'
import { ServicesResolver } from './services/services.resolver'

const routes: Routes = [
  {
    path: '',
    loadChildren: async () => (await import('./dashboard/dashboard.module')).DashboardModule,
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  {
    path: 'cameras',
    loadChildren: () => import('./cameras/cameras.module').then(m => m.CamerasModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'harvia',
    loadChildren: () => import('./harvia/harvia.module').then(m => m.HarviaModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'wholesale',
    loadChildren: () => import('./wholesale/wholesale.module').then(m => m.WholesaleModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'services',
    loadChildren: () => import('./services/services.module').then(m => m.ServicesModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
    resolve: {
      config: ServicesResolver,
    },
  },
  {
    path: 'warehouse',
    loadChildren: () => import('./warehouse/warehouse.module').then(m => m.WarehouseModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'changelogs',
    loadChildren: () => import('./changelogs/changelogs.module').then(m => m.ChangelogsModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'muurikka',
    loadChildren: () => import('./muurikka/muurikka.module').then(m => m.MuurikkaModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  { path: 'inventories', redirectTo: '/warehouse/inventories' },
  { path: 'pick-ups', redirectTo: '/warehouse/pick-ups' },
  { path: 'defects', redirectTo: '/warehouse/defects' },
  {
    path: 'crm',
    loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  { path: '**', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
