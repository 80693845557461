import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { MyharviaProductsService } from '../../app/harvia/services/myharvia-products.service';
import { MyHarviaActions } from '../actions/myharvia.actions';

@Injectable()
export class MyHarviaEffects {
  constructor(
    private actions$: Actions,
    private service: MyharviaProductsService
  ) {}

  getProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyHarviaActions.getProducts),
      exhaustMap(({ page, size }) =>
        this.service.getData(page, size).pipe(
          map((response) => MyHarviaActions.getDataSuccess(response)),
          catchError((_) => EMPTY)
        )
      )
    )
  );
}
