import { Component, OnInit } from '@angular/core';
import { AuthService } from './login/services/auth.service';

@Component({
  selector: 'fs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loaded = false;
  constructor(public service: AuthService) {}

  ngOnInit(): void {
    this.loaded = !this.service.loading;
  }

  isNotIframe() {
    return window.self === window.top;
  }
}
