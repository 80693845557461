import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { MyHarviaActions } from '../actions/myharvia.actions';

export interface IAction extends Action {
  payload?: any;
}

export interface MyHarviaState {}

const _myharviaReducer = createReducer(
  { total: 0, items: [] },
  on(MyHarviaActions.getProducts, (state) => ({ ...state, loading: true })),
  on(MyHarviaActions.getDataSuccess, (state, payload) => ({
    ...state,
    ...payload,
    loading: false,
  })),
);

export const myharvia: ActionReducer<any, any> = (state, action) => _myharviaReducer(state, action);
