import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, OnInit } from '@angular/core'
import { faAndroid } from '@fortawesome/free-brands-svg-icons'
import {
  faAddressBook,
  faAngleDoubleLeft,
  faBan,
  faBars,
  faBoxes,
  faBoxesPacking,
  faCube,
  faCubes,
  faDollyFlatbed,
  faFireAlt,
  faHome,
  faKaaba,
  faMapMarkerAlt,
  faTableList,
  faPercent,
  faUpload,
  faRobot,
  faTags,
  faTasks,
  faTimes,
  faTools,
  faUndo,
  faUserAstronaut,
  faUsers,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs/internal/Observable'
import { ADMIN_GROUP, OPERATOR_GROUP } from '@sauna-net/dto'
import { AppState } from '../../../core/reducers/index'
import { environment } from '../../../environments/environment'
import { AuthService } from '../../login/services/auth.service'
import { NGAnimations } from '@sauna-net/client/utils/ng_animations'

@Component({
  selector: 'fs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          width: '15rem',
        }),
      ),
      state('close', style({ width: '3.7rem' })),
      transition('open <=> close', [animate('0.2s')]),
    ]),
    NGAnimations.SlideUp()
  ],
})
export class HeaderComponent implements OnInit {
  isPublic = false
  auth$: Observable<{ isLoggedIn: boolean }>
  logo = environment.logo

  open = true
  mobileOpen = false
  tempOpen = true
  // icons
  faHome = faHome
  faBan = faBan
  faMapMarkerAlt = faMapMarkerAlt
  faUndo = faUndo
  faCube = faCube
  faTools = faTools
  faAndroid = faAndroid
  faTimes = faTimes
  faBars = faBars
  faBoxesPacking = faBoxesPacking
  faBoxes = faBoxes
  faDollyFlatbed = faDollyFlatbed
  faAngleDoubleLeft = faAngleDoubleLeft
  faAddressBook = faAddressBook
  faTasks = faTasks
  faFireAlt = faFireAlt
  faWarehouse = faWarehouse
  faUsers = faUsers
  faUserAstronaut = faUserAstronaut
  faTags = faTags
  faPercent = faPercent
  faUpload = faUpload
  faRobot = faRobot
  faTableList = faTableList
  faKaaba = faKaaba
  faCubes = faCubes

  constructor(public authService: AuthService, private store: Store<AppState>) {
    this.auth$ = this.store.select('auth')
    const isOpen = localStorage.getItem('NAV_TOGGLE') || '1'
    this.open = isOpen === '1'
  }
  ngOnInit(): void {
    this.store.select('router').subscribe(() => {
      if (this.mobileOpen) this.toggleMobileMenu()
    })
  }

  toggleOpen() {
    localStorage.setItem('NAV_TOGGLE', this.open ? '0' : '1')
    this.open = !this.open
  }

  toggleMobileMenu() {
    this.mobileOpen = !this.mobileOpen
  }

  mouseEnter() {
    if (!this.open) {
      this.tempOpen = this.open
      this.open = true
    }
  }

  mouseLeave() {
    if (!this.tempOpen) {
      this.open = false
      this.tempOpen = true
    }
  }

  get isAndroid() {
    return /(android)/i.test(navigator.userAgent)
  }

  get servicesGroups(): number[] {
    return [OPERATOR_GROUP, ADMIN_GROUP]
  }
}
