import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { TokenInterceptor } from '../interceptors/token.interceptor'
import csDateFormat from 'date-fns/locale/cs'
import { provideNzI18n, cs_CZ, NZ_DATE_LOCALE } from 'ng-zorro-antd/i18n'

export const app_providers = [
  { provide: 'isBrowser', useValue: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: NZ_DATE_LOCALE, useValue: csDateFormat },
  provideNzI18n(cs_CZ),
] as const
