<li
  class="fs-nav-item"
  [ngClass]="[class || '', hasChildren ? 'fs-has-child' : '', open ? 'fs-nav-open' : '']"
  mat-ripple
  [routerLinkActive]="'fs-active-item'"
  [matRippleDisabled]="noRipple || navClosed || open"
>
  <div class="fs-nav-container">
    <a
      [routerLink]="link"
      [routerLinkActive]="'fs-active'"
      [routerLinkActiveOptions]="{ exact: exact }"
      class="fs-nav-link"
      (click)="onClick($event)"
      [ngClass]="{ 'fs-inactive': noActive }"
    >
      <ng-container *ngIf="icon">
        <ng-container *ngIf="utilsService.iconIsString(icon); else elseTemplate">
          <img src="{{ icon }}" alt="D" />
        </ng-container>
        <ng-template #elseTemplate>
          <fa-icon [icon]="$any(icon)"></fa-icon>
        </ng-template>
      </ng-container>
      <div class="fs-nav-info">
        <label *ngIf="label" class="fs-nav-label">
          <span>{{ label }}</span>
        </label>
      </div>
    </a>
    <div *ngIf="hasChildren" [routerLinkActive]="'fs-active'" (click)="toggleOpen()" class="fs-nav-toggle">
      <fa-icon [icon]="open ? faMinusSquare : faPlusSquare"></fa-icon>
    </div>
  </div>
  <ng-content *ngIf="navClosed || open"></ng-content>
</li>
