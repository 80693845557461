<button
  title="Open mobile menu"
  type="button"
  mat-mini-fab
  class="fs-mobile-menu-toggle"
  (click)="toggleMobileMenu()"
>
  <fa-icon [icon]="faBars"></fa-icon>
</button>
<header
  class="header"
  [ngClass]="{ 'fs-close': !open, 'fs-mobile-open': mobileOpen }"
  [@openClose]="open ? 'open' : 'close'"
>
  <div class="fs-menu-header">
    <button title="Close mobile menu" type="button" mat-mini-fab class="fs-mobile-menu-toggle" (click)="toggleMobileMenu()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <fs-nav-item [label]="'Sauna-NET'" [link]="'/'" [exact]="true" class="fs-logo fs-no-mobile-info" [icon]="logo"> </fs-nav-item>
  </div>
  <nav aria-label="fs-navigation" class="fs-navigation">
    @if((auth$ | async)?.isLoggedIn) {
    <fs-nav-item @slideUp [link]="'/'" [exact]="true" [icon]="faHome" [label]="'Hlavni'"> </fs-nav-item>
    <fs-nav-item @slideUp [link]="'/cameras'" [icon]="'../../../assets/images/hiv_cam.png'" [label]="'Kamery'"> </fs-nav-item>
    <!-- HARVIA -->
    <fs-nav-item @slideUp [hasChildren]="true" [icon]="'../../../assets/images/harvia.png'" label="Harvia" link="/harvia" [navClosed]="!open">
      <nav aria-label="fs-child-navigation" class="fs-child-navigation">
        <fs-nav-item [link]="'/harvia/products'" [label]="'Produkty'" [noRipple]="true"> </fs-nav-item>
        <fs-nav-item [link]="'/harvia/users'" [label]="'Uživatele'" [noRipple]="true"> </fs-nav-item>
        <fs-nav-item [link]="'/harvia/courses'" [label]="'Školeni'" [noRipple]="true"> </fs-nav-item>
        <fs-nav-item [link]="'/harvia/texts'" [label]="'Texty'" [noRipple]="true"> </fs-nav-item>
      </nav>
    </fs-nav-item>
    <fs-roles-guard [groups]="servicesGroups" [users]="[182]">
      <fs-nav-item @slideUp [link]="'/services'" [icon]="faTools" [label]="'Služby'"> </fs-nav-item>
    </fs-roles-guard>
    <!-- SKLAD -->
    <fs-nav-item @slideUp link="/warehouse" [hasChildren]="true" [icon]="faWarehouse" label="Sklad" [navClosed]="!open">
      <nav aria-label="fs-child-navigation" class="fs-child-navigation">
        <fs-nav-item link="/warehouse/inventories" [icon]="faBoxes" label="Inventury" [noRipple]="true"> </fs-nav-item>
        <fs-nav-item
          link="/warehouse/pick-ups"
          [icon]="faBoxesPacking"
          label="Výdejky"
          [hasChildren]="true"
          [noRipple]="true"
          [noToggle]="true"
        >
          <nav aria-label="fs-child-navigation" class="fs-child-navigation">
            <fs-nav-item link="/warehouse/pick-ups/deadlines" label="Terminy"> </fs-nav-item>
          </nav>
        </fs-nav-item>
        <fs-nav-item [link]="'/warehouse/returns'" [icon]="faUndo" label="Vracené zboží"> </fs-nav-item>
        <fs-nav-item [link]="'/warehouse/defects'" [icon]="faBan" [label]="'2.jakost'"> </fs-nav-item>
        <fs-nav-item [link]="'/warehouse/sets'" [icon]="faCubes" [label]="'Sety'"> </fs-nav-item>
        <fs-nav-item [link]="'/warehouse/addresses'" [icon]="faMapMarkerAlt" label="Umístěni"> </fs-nav-item>
        <fs-nav-item [link]="'/warehouse/products'" [icon]="faCube" label="Produkty"> </fs-nav-item>
      </nav>
    </fs-nav-item>
    <!-- Pricing -->
    <fs-nav-item @slideUp link="/pricing" [hasChildren]="true" [icon]="faTags" label="Cenotvorba" [navClosed]="!open">
      <nav aria-label="fs-child-navigation" class="fs-child-navigation">
        <fs-nav-item link="/pricing/sales" [icon]="faPercent" label="Slevy" [noRipple]="true"> </fs-nav-item>
        <fs-nav-item link="/pricing/export" [icon]="faUpload" label="Export" [noRipple]="true"> </fs-nav-item>
        <!-- <fs-nav-item link="/sales/automate" [icon]="faRobot" label="Automat" [noRipple]="true"> </fs-nav-item> -->
      </nav>
    </fs-nav-item>
    <fs-nav-item @slideUp link="/crm" [hasChildren]="true" [icon]="faUsers" label="CRM" [navClosed]="!open">
      <nav aria-label="fs-child-navigation" class="fs-child-navigation">
        <fs-nav-item link="/crm/contacts" [icon]="faAddressBook" label="Kontakty" [noRipple]="true"></fs-nav-item>
        <fs-nav-item link="/crm/partners" [icon]="faUserAstronaut" label="Partnery" [noRipple]="true"></fs-nav-item>
      </nav>
    </fs-nav-item>
    <fs-nav-item @slideUp [hasChildren]="true" [icon]="faDollyFlatbed" label="B2B" [link]="'/wholesale'" [navClosed]="!open">
      <nav aria-label="fs-child-navigation" class="fs-child-navigation">
        <fs-nav-item [link]="'/wholesale/accounts'" [label]="'Účty'" [noRipple]="true"> </fs-nav-item>
        <fs-nav-item [link]="'/wholesale/categories'" [label]="'Kategorie'" [noRipple]="true"> </fs-nav-item>
      </nav>
    </fs-nav-item>
    <fs-nav-item @slideUp [link]="'/muurikka'" [icon]="faFireAlt" [label]="'Muurikka'"> </fs-nav-item>
    <div *ngIf="isAndroid" style="overflow: hidden">
      <a mat-raised-button color="primary" href="/static/apk/finsoft.apk">
        <span><fa-icon [icon]="faAndroid"></fa-icon> Stáhnout aplikaci</span>
      </a>
    </div>
    }
  </nav>
  <div class="fs-toggle" (click)="toggleOpen()" matRipple>
    <fa-icon [icon]="faAngleDoubleLeft"></fa-icon>
  </div>
  <ng-content> </ng-content>
</header>
<div
  *ngIf="(auth$ | async)?.isLoggedIn"
  class="fs-mobile-backdrop"
  [ngClass]="{ 'fs-show': mobileOpen }"
  (click)="mobileOpen ? toggleMobileMenu() : false"
></div>
